$basalt-gray-10: #FAFAFA;
$basalt-gray-15: #f6f6f6;
$basalt-gray-20: #E9EBEC;
$basalt-gray-30: #D9DBDD;
$basalt-gray: #646567;
$light-gray: #C5C4C7;

$white-default: #FFFFFF;
$white-primary: #F7F7F7;

$header-height: 56px;
$footer-height: 80px;
$body-head-height: 48px;
$body-height: calc(100vh - $header-height - $footer-height);
$table-height: calc(100vh - $header-height - $footer-height - $body-head-height);

$table-row-height: 56px;
$table-head-height: 40px;