@use 'styles/variables.scss' as *;

.root {
  width: 256px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 1px solid $light-gray;
  border-radius: 2px;

  .input {
    position: relative;
    width: 160px;
    border: none;
  }

  .slider {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .button {
    border-radius: 0;
    border-left: 1px solid $light-gray;
  }
}
