@use 'styles/variables.scss' as *;

.root {
  height: $body-height;

  .head {
    height: 48px;
    display: flex;
    justify-content: space-between;

    .filters {
      display: flex;
      column-gap: 8px;
    }
  }

  .content {
    height: $table-height;
    padding-top: 16px;
  }
}

.buttons {
  margin-left: auto;
  display: flex;
  column-gap: 16px;
}
