.root {
  height: 100%;

  .panel {
    display: grid;
    column-gap: 8px;
    grid-template-columns: 60% 1fr;
    height: 100%;
  }
}
