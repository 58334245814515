@use 'styles/variables.scss' as *;

.root {
  border: 1px solid $white-primary;
  background-color: $white-primary;
  height: $body-height;

  .head {
    height: $body-head-height;
    display: flex;
    column-gap: 8px;
    background-color: $basalt-gray-30;
  }

  .content {
    padding: 8px;
    height: $table-height;
  }
}

.buttons {
  display: flex;
  column-gap: 16px;
}
