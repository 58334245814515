@use 'styles/variables.scss' as *;

.root {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 4px;
  column-gap: 16px;
  width: 310px;
  border: 1px solid $light-gray;
  background-color: $white-default;
  border-radius: 2px;
  overflow: hidden;

  .slider {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .control {
    &:first-of-type {
      border-right: 1px solid $light-gray;
    }

    &:last-of-type {
      border-left: 1px solid $light-gray;
    }

    .button {
      border: none;
      border-radius: 0;
    }
  }
}
