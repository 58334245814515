@use "styles/variables.scss" as *;

.root {
  background-color: $basalt-gray-20;

  .head {
    padding: 16px;
    height: $header-height;
    display: flex;
    align-items: center;
  }

  .body {
    height: calc(100vh - $header-height);
    padding: 0 16px;
  }

  .footer {
    height: $footer-height;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }
}
